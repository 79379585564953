<template>
    <searchBody>
        <search class="searchbar" :label-width="80" :searchs="searchs" @search="onSearch" @reset="reset"></search>
        <Card class="table_container" ref="table_container">
            <Table stripe class="table mt_10" :columns="dataColumn" :data="dataList">
                <template slot-scope="{ row }" slot="userImg">
                    <previewImage class="userImg_panel" :thumWidth="50" :thumHeight="50" :src="row.userImg"></previewImage>
                </template>
                <template slot-scope="{ row }" slot="status">
                    <span :class="'now_state_' + row.status">{{ { 0: '关闭', 1: '启用' }[row.status] }}</span>
                </template>
                <template slot-scope="{ row }" slot="manager">
                    <div class="table_manager flex flex_wrap align_center">
                        <Button type="primary" size="small" ghost @click="onView(row)">详情</Button>
                    </div>
                </template>
            </Table>
        </Card>
        <div class="relative width_100_percent text_align_right">
            <Page size="small" :total="totalPageNum || 1" :page-size="1" show-elevator @on-change="currentChange" />
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </searchBody>
</template>
<script>
import pageMixins from '@/lib/pageMixins';
import tableSearch from '@/components/tableSearch';
import searchBody from '@/components/searchBody';
import previewImage from '@/components/previewImage';
import { reqUserList } from '@/lib/request/auth';
import { isNotNullObject } from '@/lib/util';

export default {
    name: 'userManagerList',
    mixins: [pageMixins],
    components: {
        search: tableSearch,
        searchBody,
        previewImage,
    },
    data() {
        return {
            searchs: [
                { id: hm.createId(), label: '编号', placeholder: '输入编号', type: 'input', bind: 'id' },
                { id: hm.createId(), label: '手机号', placeholder: '输入手机号', type: 'input', bind: 'mobile' },
                {
                    id: hm.createId(),
                    label: '状态',
                    placeholder: '选择状态',
                    type: 'select',
                    bind: 'status',
                    list: [
                        { id: '1', title: '启用' },
                        { id: '0', title: '关闭' },
                    ],
                },
            ],
            dataColumn: [
                { key: 'id', title: '编号', minWidth: 80, tooltip: true },
                { key: 'member_code', title: '会员卡号', minWidth: 120, tooltip: true },
                { slot: 'userImg', title: '头像', minWidth: 70 },
                { key: 'nickname', title: '昵称', minWidth: 120, tooltip: true },
                { key: 'mobile', title: '手机号码', minWidth: 100 },
                {
                    key: 'last_login_time',
                    title: '最后登录时间',
                    minWidth: 140,
                    render(h, data) {
                        // return h('div', new Date(data.row.last_login_time * 1000).Format('yyyy-MM-dd HH:mm:ss') + '');
                        return h('div', (data.row.last_login_time || '') + '');
                    },
                },
                { slot: 'status', title: '状态', minWidth: 60 },
                { slot: 'manager', title: '操作' },
            ],
            dataList: [],
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        getList() {
            // console.log('getList', this.params);

            this.showAjaxLoading();
            reqUserList(this.params)
                .then(res => {
                    this.dataInjectDataList(res);
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        onView(_rowInfo = {}) {
            let query = {};
            if (isNotNullObject(_rowInfo)) {
                query.id = _rowInfo.id;
            }
            this.goUrl('/userView', query);
        },
    },
};
</script>
